import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';
import Pricing from '../Nests/Pricing';
import Breadcrumb from '../../Components/Breadcrumb.js';
import queryString from "query-string"
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";
import { Time } from 'highcharts';
import {LandscapeOrientation} from '../../LandscapeOrientation.jsx';
import {PortraitOrientation} from '../../PortraitOrientation.jsx';


class UsersAll extends Component {
    interval = null;
    state = {
        loading: true,
        multiPage: false,
        redirect: "",
        JobId: -1,
        pages: [],
        data: [], 
        parts: [],
        nests: [],
        Company: [],
        Contact: []
    }

    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        
        var parts = await databaseRequest(`/nests/job/${params.JobId}`,"GET");
        var Job = await databaseRequest(`/jobs/${params.JobId}`,"GET");
        if(typeof Job.Id !=='undefined'){
            var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
            var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
        }
        const data = await Pricing.getJobNests(databaseRequest, params.JobId);
        /*
        var data = [];
        var nests = [];

        for(let i=0; i<parts.length; i++){
            if(nests.indexOf(parts[i].NestId)==-1){
                nests.push(parts[i].NestId);
            }
        }   
        for(let i=0; i<nests.length; i++){
            var nest = await databaseRequest(`/nests/${nests[i]}`,"GET");
            nest = Pricing.processData(nest);
            for(let j=0; j<nest.Parts.length; j++){
                for(let k=0; k<parts.length; k++){
                    if(nest.Parts[j].Id == parts[k].PartId){
                        nest.Parts[j].Material = Pricing.activeMaterial().Name;
                        nest.Parts[j].NumberSheets = nest.NumberSheets;
                        nest.Parts[j].Thickness = nest.Thickness;
                        nest.Parts[j].State = nest.State;
                        data.push(nest.Parts[j]);
                    }
                }
            }
        }
        data = data.sort( ( a, b ) => {
            if ( a.Name < b.Name ){
            return 1;
            }
            if ( a.Name > b.Name ){
            return -1;
            }
            return 0;
        });
        data = data.sort( ( a, b ) => {
            if ( a.Thickness < b.Thickness ){
            return -1;
            }
            if ( a.Thickness > b.Thickness ){
            return 1;
            }
            return 0;
        });

        data = data.sort( ( a, b ) => {
            if ( a.Material < b.Material ){
            return 1;
            }
            if ( a.Material > b.Material ){
            return -1;
            }
            return 0;
        });

        for(let i=0; i<data.length; i++){
            var found = false;
            var cheapest = data[i].CostEach;
            var cheapestId = data[i].Id;
            for(let j=0; j<data.length; j++){

                if(i!=j && data[i].Name==data[j].Name && data[i].Thickness==data[j].Thickness){
                    found=true;
                    if(data[j].CostEach < cheapest){
                        cheapest=data[j].CostEach;
                        cheapestId=data[j].Id;
                    }
                }
            }
            data[i].Duplicate = found;
            data[i].DuplicateDontShow = found && cheapestId!=data[i].Id;
            data[i].DuplicateId = cheapestId;
        }
        for(let i=0; i<data.length; i++){
            if(data[i].Duplicate && data[i].DuplicateDontShow){
                for(let j=0; j<data.length; j++){
                    if(i!=j && data[j].Id==data[i].DuplicateId){
                        data[j].Quantity += data[i].Quantity*data[i].NumberSheets;
                    }
                }
            }
        }*/
        var pages = [];
        var page = [];
        var multiPage = data.length > 8;
        let count = 0;
        for(let i=0; i<data.length; i++){
            if(!data[i].DuplicateDontShow){
                count++;
                if(count > 16){
                    count = 0;
                    pages.push(page);
                    page = [];     
                }
                page.push(data[i]);  
            }
            
        }
        if(page.length>0){
            pages.push(page);
        }
        
        
        this.setState({
            pages: pages,
            multiPage: multiPage,
            data: data,
            loading: false,
            Job: Job,
            Company: Company,
            Contact: Contact,
            JobId: params.JobId
        })
    }
    stateClass = (state) =>{
        // className="card w-20 m-4"
        switch(state){
           case -1: return "card float-left badge-soft-danger d-none m-4"; 
           case 0: return "card float-left badge-soft-warning text-dark m-4"; 
           case 1: return "card float-left badge-soft-success text-dark m-4"; 
           case 2: return "card float-left badge-soft-info text-dark m-4"; 
        }
        return "";
    }
    header = (pg)=>{
        var {Job, Company,Contact, multiPage} = this.state;
        var client = Company.Name;
        if(Contact.Id==-2){
            client = `[${Contact.Name}]`;
        }
            if(multiPage){
                return (
                    <div className={pg > 0?"":""}>
                    <h3 className="m-0" style={{pageBreakBefore:pg>0?"always":"auto"}}>
                        <i className="fe fe-sunset"></i> {Job.Id +" - "+ client} {Job.Name} <small className="float-right">Page: {pg+1}</small> 
                    </h3>
                </div>);
            }
            else{
                if(pg==0){
                    return (<div>
                        <h3 className="m-0">
                            <i className="fe fe-sunset"></i> {Job.Id +" - "+ client} {Job.Name} <small className="float-right">Page: {pg+1}</small> 
                        </h3>
                    </div>);
                }
            }

        


    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading laser parts...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    table = (data) => {    
        //const {data} = this.state;
        var itemNo = 0;
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading laser parts...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <> {data.map((row, i) => {  
                        var files = row.Name.split("\\r\\n");
                        var file = "";
                        for(var j=0; j<files.length; j++){
                            if(files[j].length > 0){
                                file = files[j];
                            }
                        } 
                        var shortMaterial = row.Material.replaceAll("Stainless Steel", "SS");
                        shortMaterial = shortMaterial.replaceAll("Mild Steel", "MS");
                        shortMaterial = shortMaterial.replaceAll("Bisalloy", "BIS");
                        shortMaterial = shortMaterial.replaceAll("Aluminium", "ALU");  
                        var weight = row.Weight.toFixed(2)+"kg"; 
                        if(row.Weight < 1){
                            weight = row.Weight.toFixed(0)+"g"; 
                        }
                        if(row.DuplicateDontShow){
                            return (<></>);
                        }
                        itemNo++;
                        return (

                            <div  key={i} className="card w-20 m-0 border-blk p-1 float-left" style={{width: "205px"}}>
                            <img className="nest-img-print card-img-top card-img-bottom mx-auto mt-2" src={row.Image} style={{height: "160px", width: "160px"}} alt="Card image cap" />
                            <div className="card-body p-1">
                                <h5 className="card-title m-0"><strong>#{itemNo} {shortMaterial} {row.Thickness.toFixed(1)}mm</strong><br/><em>({row.BoundingBoxX.toFixed(1)} x {row.BoundingBoxY.toFixed(1)}) {weight}</em></h5>
                                
                                <div className="card-text">
                                <div className='row m-1'>
                                        <div className='col'>
                                        ${row.CostEach.toFixed(2)}ea
                                        </div>
                                        <div className='col'>
                                        {row.ActualQty} off
                                        </div>
                                    </div>
                                    {row.Duplicate?<strong className="m-0 p-0" style={{fontSize: "0.75rem"}}>CUT IN MULTIPLE NESTS</strong>:<></>}
                                    {
                                    row.Name.split("\\r\\n").map((text,j)=>{
                                        if(text.length > 0){return (<p key={j} className='mb-0 mt-0 p-0' style={{fontSize: '0.75rem'}}> &gt; {this.camelize(text)}</p>);}
                                    
                                    })}

            
                                    {data.State==1 && row.JobId!=-1?(<>
                                        <p className="form-control text-right text-muted">{this.jobName(row.JobId.toString())}</p>
                                    </>):(<></>)}
            
                                </div>
                            </div>
                            </div>
                        )
            
                    })}
                    </>
                    );
            }
    }
    summary = (data) =>
    {
        var total = 0;
        var line = {fontSize: "1rem", padding: "0px", margin: "0px"}; 
        var itemNo = 0;


        return (<div className="row form-group pl-5 pr-5 d-flex justify-content-center" >
                <table className="table text-black" >
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">#</th>   
                    <th scope="col" className='text-left' style={line}>Nest</th>
                    <th scope="col" className='text-left' style={line}>Sheets</th>
                    <th scope="col" className='text-left' style={line}>Material</th>
                    <th scope="col" className='text-left' style={line}>Part</th>
                    <th scope="col" className='text-right' style={line}>Price</th>                  
                    <th scope="col" className='text-right' style={line}>Qty</th>              
                    <th scope="col" className='text-right' style={line}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => {
                        var lineTotal = row.CostEach*row.ActualQty;
                        total += lineTotal;
                        var nameSpl = row.Name.split("\\r\\n");
                        var name = "";

                        var shortMaterial = row.Material.replaceAll("Stainless Steel", "SS");
                        shortMaterial = shortMaterial.replaceAll("Mild Steel", "MS");
                        shortMaterial = shortMaterial.replaceAll("Aluminium", "ALU");
                        shortMaterial = shortMaterial.replaceAll("Bisalloy", "BIS");

                        for(let j=0; j<nameSpl.length; j++){
                            if(nameSpl[j].length > 0){
                                name += j>0?" > ":""
                                name += nameSpl[j];
                            } 
                        }
                        if(row.DuplicateDontShow){
                            return (<></>);
                        }

                        itemNo++;
                        return (
                        <tr key={i}>
                            <th scope="row" style={line}>{itemNo}</th>
                            <td style={{...line, fontSize: "0.75rem"}}>{row.NestName}</td>
                            <td style={{...line, fontSize: "0.75rem"}}>{row.NumberSheets}</td>
                            <td style={{...line, fontSize: "0.75rem"}} className='font-weight-bold'>{shortMaterial} [{row.Thickness.toFixed(1)}mm]</td>
                            <td style={{...line, fontSize: "0.75rem"}}>{name}{row.Duplicate?<strong> - CUT IN MULTIPLE NESTS</strong>:<></>}</td>
                            <td className='text-right' style={line}>${row.CostEach.toFixed(2)}</td>
                            <td className='text-right' style={line}>{row.ActualQty}</td>
                            <td className='text-right' style={line}>${lineTotal.toFixed(2)}</td>
                        </tr>)
                    })}
                <tr className='bg-light'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                <tr className='text-black'>
                    <td colSpan="6" className='font-weight-bold text-right'>Total</td>
                    <td colSpan="2" className='text-right'>${total.toFixed(2)}</td>
                </tr>
                </tbody>
                </table>
        </div>);
    }
    camelize(str) {
        str = str.toLowerCase();
        return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    render(){
        const {props} = this;
        const {data, Job, pages} = this.state;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        

        return (
            <main role="main" className="p-0 text-black">
                <PortraitOrientation/>
                <div className="p-0">
                {pages.map((pg, i)=>{

                    return (
                    <div key={i}>
                        {this.header(i)}
                        <div className="row form-group m-0 p-0 d-flex justify-content-center"> {/* */}
                            {this.table(pg)}
                        </div>
                    </div>);
                })}
                
                {this.header(pages.length)}
                {this.summary(data)}
                


                </div>
            </main>
        )



        return (
            <main role="main" className="p-5 text-black">
                <LandscapeOrientation/>
            <div className="p-5">
                <h2><i className="fe fe-sunset"></i> Laser Parts</h2>
                <div className="row form-group">
                    <Breadcrumb {...props} JobId={this.state.JobId} Active="Laser"/>
                </div>
                <div className="row form-group mt-4 d-flex justify-content-center">
                        {this.table()}
                </div>
                

                <div className="form-row justify-content-between">
                        <div className="form-group col-mx-auto">
                            <Link to={`/jobs/${this.state.JobId}?return=${query.return}`}><button type="button" className="btn btn-secondary float-right">Back</button></Link>
                        </div>
                 </div>
            </div>

            </main>
        );
    }
}

export default UsersAll;