import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';
import Pricing from '../Nests/Pricing';
import Breadcrumb from '../../Components/Breadcrumb.js';
import queryString from "query-string"
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";
import { Time } from 'highcharts';
import ReactToPrint from 'react-to-print';
import JobsLaserPrint from './JobsLaserPrint.js';

class UsersAll extends Component {
    interval = null;
    state = {
        loading: true,
        redirect: "",
        JobId: -1,
        data: [], 
        parts: [],
        nests: []
    }

    componentDidMount(){
        this.reload();
    }

    reload = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        setPage("Laser Parts")
        const data = await Pricing.getJobNests(databaseRequest, params.JobId);
        this.setState({
            data: data,
            loading: false,
            JobId: params.JobId
        })
    }
    removeJobAssoc = async (id) =>{
        const {databaseRequest}=this.props;
        await databaseRequest(`/nestparts/${id}`,"PUT", JSON.stringify({JobId: -1}));
        this.reload();

    }
    stateClass = (state) =>{
        // className="card w-20 m-4"
        switch(state){
           case -1: return "card float-left badge-soft-danger d-none m-2"; 
           case 0: return "card float-left badge-soft-warning text-dark m-2"; 
           case 1: return "card float-left badge-soft-success text-dark m-2"; 
           case 2: return "card float-left badge-soft-info text-dark m-2"; 
        }
        return "";
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">Loading laser parts...</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    table = () => {    
        const {data} = this.state;
        var itemNo = 0;
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading laser parts...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <> {data.map((row, i) => {  
                        var files = row.Name.split("\\r\\n");
                        var file = "";
                        for(var j=0; j<files.length; j++){
                            if(files[j].length > 0){
                                file = files[j];
                            }
                        }    
                        if(row.DuplicateDontShow){
                            return (<></>);
                        } 
                        itemNo++;                   
                        return (
                            <Link to={"/nests/"+row.NestId} key={i}><div   className={this.stateClass(row.State)} style={{width: "250px"}}>
                            <img className="card-img-top card-img-bottom mx-auto  mt-2" src={row.Image} style={{height: "200px", width: "200px"}} alt="Card image cap" />
                            <div className="card-body">
                                <h5 className="card-title"><strong>#{itemNo} {row.Material.toUpperCase()} - {row.Thickness.toFixed(1)}mm</strong> <em>
                                    {row.BoundingBoxX.toFixed(1)} x {row.BoundingBoxY.toFixed(1)} - {row.Weight.toFixed(3)}kg</em></h5>
                                <div className="card-text">
                                    <p className='m-2'>{file}</p>

                                    <p className='m-0'><small>Cut: ${row.CostCut.toFixed(2)}</small></p>
                                    <p className='m-0'><small>Material: ${row.CostMat.toFixed(2)}</small></p>
                                    <p className='m-0'><small>Handling: ${row.CostHand.toFixed(2)}</small></p>
                                    <p className='font-weight-bold mt-2'><small>Approx.</small> ${row.CostEach.toFixed(2)}ea - {row.ActualQty} off</p>
                                    {row.NumberSheets>1?(<p className='text-danger font-weight-bold mt-2'>Sheets: {row.NumberSheets} ({row.Quantity} per sheet)</p>):<></>}
                                </div>
                                {row.Duplicate?<strong className="text-danger m-0 p-0" style={{fontSize: "0.75rem"}}>CUT IN MULTIPLE NESTS</strong>:<></>}
                            </div>
                            </div>
                            </Link>
                        )
            
                    })}
                    </>
                    );
            }
    }
    camelize(str) {
        str = str.toLowerCase();
        return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    summary = (data) =>
        {
            var total = 0;
            var line = {fontSize: "1rem", padding: "0px", margin: "0px"}; 
            var itemNo = 0;
    
    
            return (<div className="row form-group pl-2 pr-2 d-flex justify-content-center" >
                    <table className="table text-black" >
                    <thead className="thead-dark">
                        <tr>
                        <th></th>
                        <th scope="col">#</th>   
                        <th scope="col" className='text-left' style={line}>Nest</th>
                        <th scope="col" className='text-left' style={line}>Sheets</th>
                        <th scope="col" className='text-left' style={line}>Material</th>
                        <th scope="col" className='text-left' style={line}>Part</th>
                        <th scope="col" className='text-right' style={line}>Price</th>                  
                        <th scope="col" className='text-right' style={line}>Qty</th>              
                        <th scope="col" className='text-right' style={line}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, i) => {
                            var lineTotal = row.CostEach*row.ActualQty;
                            total += lineTotal;
                            var nameSpl = row.Name.split("\\r\\n");
                            var name = "";
    
                            var shortMaterial = row.Material.replaceAll("Stainless Steel", "SS");
                            shortMaterial = shortMaterial.replaceAll("Mild Steel", "MS");
                            shortMaterial = shortMaterial.replaceAll("Aluminium", "ALU");
                            shortMaterial = shortMaterial.replaceAll("Bisalloy", "BIS");
                            for(let j=0; j<nameSpl.length; j++){
                                if(nameSpl[j].length > 0){
                                    name += j>0?" > ":""
                                    name += nameSpl[j];
                                } 
                            }
                            if(!row.DuplicateDontShow){
                                
                                itemNo++;
                            }
                            
                            
                            return (
                            <tr key={i} className={row.DuplicateDontShow?"text-muted":""}>
                                <th style={{...line, width: "25px"}} scope="row"><i className="fe fe-x-circle link text-danger" onClick={()=>{this.removeJobAssoc(row.Id)}}></i></th>
                                <td style={line}>{itemNo}</td>
                                <td style={{...line, fontSize: "0.75rem"}}>{row.NestName}</td>
                                <td style={{...line, fontSize: "0.75rem"}}>{row.NumberSheets}</td>
                                <td style={{...line, fontSize: "0.75rem"}} className='font-weight-bold'>{shortMaterial} [{row.Thickness.toFixed(1)}mm]</td>
                                <td style={{...line, fontSize: "0.75rem"}}>{name}{row.Duplicate?<strong> - CUT IN MULTIPLE NESTS ({row.OrigQty} off)</strong>:<></>}</td>
                                <td className='text-right' style={line}>${row.CostEach.toFixed(2)}</td>
                                <td className='text-right' style={line}>{row.ActualQty}</td>
                                <td className='text-right' style={line}>${lineTotal.toFixed(2)}</td>
                            </tr>)
                        })}
                    <tr className='bg-light'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                    <tr className='text-black'>
                        <td colSpan="7" className='font-weight-bold text-right'>Total</td>
                        <td colSpan="2" className='text-right'>${total.toFixed(2)}</td>
                    </tr>
                    </tbody>
                    </table>
            </div>);
        }
    render(){
        const {props} = this;
        const {data} = this.state;
        const query = queryString.parse(this.props.location.search);
        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        var total = 0;
        return (
            <main role="main" className="mt-5">
            <div className="p-5">
                <h2><i className="fe fe-sunset"></i> Laser Parts</h2>
                <div className='row form-group'>
                    <div className='col'>
                    <Breadcrumb {...props} JobId={this.state.JobId} Active="Laser"/>
                    </div>
                    <div className='col'>
                            <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back</button>
                            <ReactToPrint
                                        trigger={() => {
                                            return <button type="button" className="btn btn-secondary float-right mr-2">Print</button>
                                        }}
                                        content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}><JobsLaserPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                    </div>
                </div>
                <div className="row form-group mt-4 d-flex justify-content-center">
                        {this.table()}
                </div>
                {/*<div className="row form-group mt-4 d-flex justify-content-center">
                    <div className='col-2'></div>
                    <div className='col-10'>
                        <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr>
                            <th scope="col">#</th>   
                            <th scope="col" className='text-left'>Nest</th>
                            <th scope="col" className='text-left'>Thickness</th>
                            <th scope="col" className='text-left'>Part</th>
                            <th scope="col" className='text-center'>Price</th>                  
                            <th scope="col" className='text-center'>Qty</th>              
                            <th scope="col" className='text-center'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, i) => {
                                var lineTotal = row.CostEach*row.Quantity;
                                total += lineTotal;
                                var nameSpl = row.Name.split("\\r\\n");
                                var name = "";
                                for(let j=0; j<nameSpl.length; j++){
                                    if(nameSpl[j].length > 0){
                                        name += " > "
                                        name += nameSpl[j];
                                    }
                                    
                                }
                                var shortMaterial = row.Material.replaceAll("Stainless Steel", "SS");
                                shortMaterial = shortMaterial.replaceAll("Mild Steel", "MS");
                                shortMaterial = shortMaterial.replaceAll("Aluminium", "ALU");      
                                return (
                                <tr key={i}>
                                    <th scope="row" >{1+i}</th>
                                    <td><Link to={`/nests/${row.NestId}`}>{row.NestName}</Link></td>
                                    <td>{row.Thickness.toFixed(1)}</td>
                                    <td>{name}</td>
                                    <td className='text-right'>${row.CostEach.toFixed(2)}</td>
                                    <td className='text-right'>{row.Quantity}</td>
                                    <td className='text-right'>${lineTotal.toFixed(2)}</td>
                                </tr>)
                            })}
                        <tr className='bg-light'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            </tr>
                        <tr className='bg-dark text-light'>
                            <td colSpan="6" className='font-weight-bold text-right'>Total</td>
                            <td className='text-right'>${total.toFixed(2)}</td>
                        </tr>
                        </tbody>
                        </table>
                    </div>
                </div>*/}{this.summary(data)}

                <div className="form-row justify-content-between">
                        <div className="form-group col-mx-auto">
                            <Link to={`/jobs/${this.state.JobId}?return=${query.return}`}><button type="button" className="btn btn-secondary float-right">Back</button></Link>
                        </div>
                 </div>
            </div>

            </main>
        );
    }
}

export default UsersAll;