import React, { Component } from 'react';
import NavBar from '../NavBar/NavBar.js';

import Users from './Users/Users.js';
import UsersEdit from './Users/UsersEdit.js';
import Account from './Users/Account.js';
import ProductEdit from './Products/ProductEdit.js';


import Operators from './Operators/Operators.js'
import OperatorsEdit from './Operators/OperatorsEdit.js'

import Jobs from './Jobs/Jobs.js';
import JobsPrint from './Jobs/JobsPrint.js';
import JobSearch from './Jobs/Search.js'

import JobsEdit from './Jobs/JobsEdit.js';
import JobsEditPrint from './Jobs/JobsEditPrint.js'

import ProcessAllocation from './Jobs/ProcessAllocation.js';
import JobsTimesheet from './Jobs/JobsTimesheet.js'
import JobsLaser from './Jobs/JobsLaser.js'
import JobsLaserPrint from './Jobs/JobsLaserPrint.js'

import Companies from './Companies/Companies.js'
import CompaniesEdit from './Companies/CompaniesEdit.js'

import Suppliers from './Suppliers/Suppliers.js'
import SuppliersEdit from './Suppliers/SuppliersEdit.js'

import PurchaseOrders from './Purchasing/PurchaseOrders.js';
import PurchaseOrderEdit from './Purchasing/PurchaseOrderEdit.js';
import PurchaseOrderEditPrint from './Purchasing/PurchaseOrderEditPrint.js';

import Contacts from './Contacts/Contacts.js'


import Controllers from './Controllers/Controllers.js'


import ScheduleJobSheet from './Schedule/JobSheet.js'
import ScheduleJobSheetPrint from './Schedule/JobSheetPrint.js'


import ScheduleJobSheetUrgent from './Schedule/JobSheetUrgent.js'
import ScheduleJobSheetUrgentPrint from './Schedule/JobSheetUrgentPrint.js'

import ScheduleLaserJobs from './Schedule/LaserJobs.js'
import ScheduleLaserJobsPrint from './Schedule/LaserJobsPrint.js'

import ScheduleOperatorProcess from './Schedule/OperatorProcess.js'
import ScheduleOperatorsList from './Schedule/OperatorsList.js'

import Timesheet from './Timesheet/Timesheet.js'
import TimesheetManualInput from './Timesheet/ManualInput.js'
import TimesheetSearch from './Timesheet/Search.js'

import Dashboard from './Dashboard/Home.js'
import JobList from './Dashboard/JobList.js';

import ProcessListMachining from './Dashboard/ProcessListMachining.js'
import ProcessListFabrication from './Dashboard/ProcessListFabrication.js'
import ProcessListFabricationPrint from './Dashboard/ProcessListFabricationPrint.js'

import ProcessListWelding from './Dashboard/ProcessListWelding.js'
import Nests from './Nests/Nests.js'
import NestsEdit from './Nests/NestEdit.js'
import NestsEditPrint from './Nests/NestEditPrint.js'

import History from './History';
import Log from './Log.js'
import Redirector from './Redirector';


import {Route, Redirect} from "react-router-dom";
import queryString from "query-string"

class Systems extends Component {
    timeMovedLast = 0;
    state = {
        navBarVisible: true,
        navBarVisibleInt: null,
        timeout: 3000,
        downloadRequest: false
    }
    inUserList = (arr) =>{
        arr.push(999);
        var UserId = localStorage.getItem('user');
        //UserId = 8; Test users here
        var found = false;
        for(let i=0; i<arr.length; i++){
            if(arr[i]==UserId || arr[i]==-1){
                found=true;
            }
        }
        return found;
    }
    async componentDidMount(){
        const {databaseRequest}=this.props;
        let t = this;

        //http://127.0.0.1:3000/purchasing/1203/print?print_token=download

        clearTimeout(t.state.navBarVisibleInt);
        t.setState({ 
            navBarVisible: false,
            navBarVisibleInt: setTimeout(()=>{
                var d = (new Date()).getTime();
                if((d-t.timeMovedLast) > t.state.timeout){
                    t.setState({navBarVisible:false})
                }
                
            },t.state.timeout)
         });
         const UserId = localStorage.getItem('user');
         var user = await databaseRequest(`/users/${UserId}`,"GET")
         localStorage.setItem('access', user.Access);
    }
    componentWillUnmount(){
        let t = this;
        clearTimeout(t.state.navBarVisibleInt);
    }
    _onMouseMove(e) {
        let t = this;
        this.timeMovedLast = (new Date()).getTime();
        if(!t.state.navBarVisible){
            t.setState({navBarVisible:true})
        }
    }
    render(){
        const {setPage}=this.props;
        const {props}=this;
        const t = this;


        const query = queryString.parse(this.props.location.search);
        var fullscreen = false;
        if(typeof query.fullscreen!=='undefined'){
            fullscreen = query.fullscreen==="true"?true:false;
        }
        var access = localStorage.getItem("access");

        return (
            <div onMouseMove={this._onMouseMove.bind(this)} onTouchMove={this._onMouseMove.bind(this)} className="p-1 h-100">
                {fullscreen?(<React.Fragment></React.Fragment>):(<NavBar visible={true} {...props} setPage={setPage}/>)}
                

                {access>=20?(<Route exact path="/jobs-all"  render={()=>(<Jobs {...props} view="all"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs-active"  render={()=>(<Jobs {...props} view="active"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs-outstanding"  render={()=>(<Jobs {...props} view="outstanding"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs-completed"  render={()=>(<Jobs {...props} view="completed"/>)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/jobs-invoicing"  render={()=>(<Jobs {...props} view="invoicing"/>)}/>):(<Redirect to="/home"/>)}

                {access>=20?(<Route exact path="/jobs-workshop"  render={()=>(<Jobs {...props} view="workshop"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs-quoting"  render={()=>(<Jobs {...props} view="quoting"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs-scheduling"  render={()=>(<Jobs {...props} view="scheduling"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/urgent"  render={()=>(<Jobs {...props} view="urgent"/>)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/search"  render={()=>(<JobSearch {...props} />)}/>):(<Redirect to="/home"/>)}
                
                {access>=20?(<Route exact path="/jobs-print"  render={()=>(<JobsPrint {...props} view="all"/>)}/>):(<Redirect to="/home"/>)}
           
                {access>=20?(<Route exact path="/jobs/:JobId" render={({match})=>(<JobsEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs/:JobId/print" render={({match})=>(<JobsEditPrint  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                
                {access>=20?(<Route exact path="/jobs/:JobId/processes" render={({match})=>(<ProcessAllocation  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs/:JobId/timesheet" render={({match})=>(<JobsTimesheet  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs/:JobId/laser" render={({match})=>(<JobsLaser  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/jobs/:JobId/laser/print" render={({match})=>(<JobsLaserPrint  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=100?(<Route exact path="/users/all" render={()=>(<Users {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=100?(<Route exact path="/users/user/:UserId" render={({match})=>(<UsersEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}


                {access>=100?(<Route exact path="/product/:ProductId" render={({match})=>(<ProductEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=50?(<Route exact path="/system/operators" render={()=>(<Operators {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/system/operators/:OperatorId" render={({match})=>(<OperatorsEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=50?(<Route exact path="/system/companies" render={()=>(<Companies {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/system/companies/:CompanyId" render={({match})=>(<CompaniesEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}


                {access>=50?(<Route exact path="/suppliers" render={()=>(<Suppliers {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/system/suppliers" render={()=>(<Suppliers {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/system/suppliers/:SupplierId" render={({match})=>(<SuppliersEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=50?(<Route exact path="/purchasing" render={()=>(<PurchaseOrders {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/purchasing/:PurchaseOrderId" render={({match})=>(<PurchaseOrderEdit  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=50?(<Route exact path="/purchasing/:PurchaseOrderId/print" render={({match})=>(<PurchaseOrderEditPrint  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=50?(<Route exact path="/system/contacts" render={()=>(<Contacts {...props} />)}/>):(<Redirect to="/home"/>)}


                <Route exact path="/nests" render={()=>{
                        return t.inUserList([2,3,5,6,7])?(<Nests {...props} />):(<Redirect to="/home"/>);
                }}/>
                <Route exact path="/nests/:NestId" render={({match})=>{
                        return t.inUserList([2,3,5,6,7])?(<NestsEdit  {...match} {...props} />):(<Redirect to="/home"/>);
                }}/>
                <Route exact path="/nests/:NestId/print" render={({match})=>{
                        return t.inUserList([2,3,5,6,7])?(<NestsEditPrint  {...match} {...props} />):(<Redirect to="/home"/>);
                }}/>
                

                


                {access>=100?(<Route exact path="/system/controllers" render={()=>(<Controllers {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=20?(<Route exact path="/schedule/job-sheet" render={()=>(<ScheduleJobSheet {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/schedule/job-sheet/print" render={()=>(<ScheduleJobSheetPrint {...props} />)}/>):(<Redirect to="/home"/>)}




                {access>=20?(<Route exact path="/schedule/job-sheet/urgent" render={()=>(<ScheduleJobSheetUrgent {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/schedule/job-sheet/urgent/print" render={()=>(<ScheduleJobSheetUrgentPrint {...props} />)}/>):(<Redirect to="/home"/>)}


                {access>=20?(<Route exact path="/schedule/laser-jobs" render={()=>(<ScheduleLaserJobs {...props} />)}/>):(<Redirect to="/home"/>)}
                {access>=20?(<Route exact path="/schedule/laser-jobs/print" render={()=>(<ScheduleLaserJobsPrint {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=20?(<Route exact path="/schedule/operator-processes" render={()=>(<ScheduleOperatorsList {...props} />)}/>):(<Redirect to="/home"/>)}

                {access>=20?(<Route exact path="/schedule/operator-processes/:OperatorId" render={({match})=>(<ScheduleOperatorProcess  {...match} {...props} />)}/>):(<Redirect to="/home"/>)}


                {access>=20?(<Route exact path="/home" render={()=>(<Dashboard {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                {access==15?(<Route exact path="/home" render={()=>(<ScheduleLaserJobs {...props} />)}/>):(<React.Fragment></React.Fragment>)}

                {access>=20?(<Route exact path="/schedule/job-list" render={()=>(<JobList {...props} />)}/>):(<></>)}
                


                {access>=20?(<Route exact path="/schedule/process-list/machining" render={()=>(<ProcessListMachining {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                {access>=20?(<Route exact path="/schedule/process-list/fabrication" render={()=>(<ProcessListFabrication {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                {access>=20?(<Route exact path="/schedule/process-list/welding" render={()=>(<ProcessListWelding {...props} />)}/>):(<React.Fragment></React.Fragment>)}


                
                {access>=20?(<Route exact path="/schedule/process-list/fabrication/print" render={()=>(<ProcessListFabricationPrint {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                
                {access>=100?(<Route exact path="/timesheet/manual" render={({match})=>(<TimesheetManualInput  {...match} {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                {access>=100?(<Route exact path="/timesheet/search" render={({match})=>(<TimesheetSearch  {...match} {...props} />)}/>):(<React.Fragment></React.Fragment>)}
                {/*access>=100?(<Route exact path="/timesheet/:OperatorId" render={({match})=>(<Timesheet  {...match} {...props} />)}/>):(<React.Fragment></React.Fragment>)*/}
                
                
                {access>=50?(<Route exact path="/phonebook" render={()=>(<Companies {...props} />)}/>):(<Redirect to="/home"/>)}
                
                {access>=10?(<Route exact path="/users/account" render={()=>(<Account {...props} />)}/>):(<Redirect to="/home"/>)}

                <Route exact path="/" render={()=>(<Redirect to="/home"/>)}/>
                <Route exact path="/redirector" render={()=>(<Redirector {...props} />)}/>
                <Route path="/history/:Guid/:Redirect" render={({match})=>(<History  {...match} {...props} />)}/>
                <Route path="/system/log/:page" render={({match})=>(<Log  {...match} {...props} />)}/>
            </div>
            
        )
    }
}
export default Systems;