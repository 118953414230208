import React, { Component, Fragment } from 'react';
import {Link, Redirect} from "react-router-dom";
import TimeAgo from '../../Components/TimeAgo.js';
import Pricing from './Pricing.js';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import queryString from "query-string"

class Nests extends Component {
    disableLoad = false
    state = {
        loading: true,
        searchComponents: {
            Description: "",
            Material: "",
            Thickness: -1,
            State: -1
        },
        search: "",
        redirect: "",
        pg: 0,
        url: "",
        data: [],
        filerState: -1
    }
    setFilter = (state)=>{
        let t = this;
        let searchComponents = this.state.searchComponents;

        if(searchComponents.filerState==state){
            state=-1;
        }
        searchComponents.State = state;
        this.searchInput.focus();
        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        setTimeout(()=>{
            t.load();
        },200);
    }
    atBottom = () =>{
        let t = this;
        this.setState({
            pg: this.state.pg+1
        });
        setTimeout(()=>{
            if(!t.disableLoad){
                t.load();
            }      
        },100)
    }

    onSearch = (e) => {
        let t = this;
        let searchComponents = this.state.searchComponents;
        searchComponents[e.target.name]=e.target.value;



        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        setTimeout(()=>{
            t.load();
        },200);
    }
    clearSearch = () =>{
        let t = this;
        let searchComponents = this.state.searchComponents;
        searchComponents.Description = "";
        searchComponents.Thickness = -1;
        searchComponents.Material = "";
        searchComponents.State = -1;
        this.setState({data: [], pg: 0, searchComponents: searchComponents});
        this.searchInput.focus();
        setTimeout(()=>{
            t.load();
        },200);
    }

    updateState = async (NestId, newState) =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Updating nest...`
        });
        var data = this.state.data;
        var req = {
            url: `/nests/${NestId}`,
            method: "PUT"
        }
        if(NestId!=="-1"){
            var data = {
                State: newState
            }
            var newData = await databaseRequest(req.url,req.method, JSON.stringify(data));
            this.setState({
                redirect: "/redirector?r=nests",
                loading: false,
                loadingMsg: `Loading nests...`
            });
        }
    }
    deleteNest = (NestId) =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting nest..."
        });
        databaseRequest(`/nests/${NestId}`,"DELETE").then(()=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading nest...",
                    redirect: "/redirector?r=nests"
                });
            }, 200);
        });

    }
    stateClass = (state) =>{
        switch(state){
           case -1: return "card float-left badge-soft-danger d-none"; 
           case 0: return "card float-left badge-soft-warning text-dark"; 
           case 1: return "card float-left badge-soft-success text-dark"; 
           case 2: return "card float-left badge-soft-info text-dark"; 
        }
        return "";
    }

    componentDidMount = () =>{
        const {databaseRequest,setPage}=this.props;
        let searchComponents = this.state.searchComponents;
        

        setPage(`Laser Nests`);
        /*NEST STATES
        0 - Not Associated - Warning
        1 - Cut - Primary
        2 - Quote - Info

        -1 - Delete - Not Shown
        */
        var query = queryString.parse(this.props.location.search);
        if(typeof query.description !== 'undefined'){
            searchComponents.Description = query.description;
        }
        if(typeof query.state !== 'undefined'){
            searchComponents.State = query.state;
        }
        if(typeof query.thickness !== 'undefined'){
            searchComponents.Thickness = query.thickness;
        }
        if(typeof query.material !== 'undefined'){
            searchComponents.Material = query.material;
        }

        this.load();
    }
    load = () =>{
        const {databaseRequest,params}=this.props;
        let searchComponents = this.state.searchComponents;
        
        let t =this;
        var url = "/nests?pg="+t.state.pg;

        if(searchComponents.State!=-1){
            url += "&state="+encodeURIComponent(searchComponents.State);
        }
        if(searchComponents.Description.length > 0){
            url += "&description="+encodeURIComponent(searchComponents.Description);
        }
        if(searchComponents.Thickness!=-1){
            url += "&thickness="+encodeURIComponent(searchComponents.Thickness);
        }
        if(searchComponents.Material.length > 0){
            url += "&material="+encodeURIComponent(searchComponents.Material);
        }

        this.props.history.replace(url)
        

        databaseRequest(url,"GET").then((newData)=>{
            var data = t.state.data;
            for(let i=0; i<newData.length; i++){
               // data.Prediction = data.CostCutting == 0;
                newData[i].Prediction = newData[i].CostCutting == 0;
                newData[i] = Pricing.processData(newData[i]);

                var found = false;
                for(let j=0; j<data.length; j++){
                    if(data[j].Id==newData[i].Id){
                        found=true;
                    }
                }
                if(!found){
                    data.push(newData[i]);   
                }
                        
            }
            

            
            t.setState({
                url: url,
                data:data,
                loading:false,
            })
        });
    }

    minsToTime(min){
        var hh = Math.floor(min/60);
        var mm = (min - (hh*60));
        var ss = (min%1)*60;
        var hhStr = hh<10?"0"+hh.toFixed(0):hh.toFixed(0);
        var mmStr = mm<10?"0"+mm.toFixed(0):mm.toFixed(0);
        var ssStr = ss<10?"0"+ss.toFixed(0):ss.toFixed(0);
        return  hhStr+ ":" + mmStr + ":" +  ssStr;
    }
    buttonState(state, activeState, inActiveClass){
        if(state==0)return `btn btn-${inActiveClass} float-right ml-2`;
        if(state==activeState)return `btn btn-${inActiveClass} float-right ml-2`;
        if(state!=activeState)return `btn btn-outline-${inActiveClass} float-right ml-2`;
        //btn-outline-secondary
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading nests...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                
                return (
                    <div className="col" ref={this.scrollRef}>
                        {this.state.data.map((row, i) => {

                            return (
                            <div key={i} className={this.stateClass(row.State)} style={{width: "50%"}}>
                            <Link to={`/nests/${row.Id}`} onClick={()=>{this.disableLoad=true;}}><img src={row.Image} className="card-img-top cursor-pointer" alt={row.JobName}/></Link>
                            <div className="card-body">
                              <h5 className="card-title font-weight-bold">{row.JobName}</h5>
                              <h5><b><TimeAgo time={row.CreatedDateTime} /></b> ({new Date(row.CreatedDateTime).toLocaleString()})</h5>
                              <p className="card-text">
                                <p className="mb-0">{row.MaterialName} {row.Thickness.toFixed(1)}mm</p>
                                <p className="mb-0">{row.SheetSizeX.toFixed(1)} x {row.SheetSizeY.toFixed(1)} - {row.Weight.toFixed(1)}kg</p>
                                <p className="mb-0">{this.minsToTime(row.RunTime)}</p>
                                <p className={row.Prediction?"mb-0 font-italic":"mb-0 font-weight-bold"}>C:${(row.CostCutting).toFixed(0)} + M:${(row.CostMaterial).toFixed(0)} + H:${(row.CostHandling).toFixed(0)} = ${(row.CostCutting+row.CostMaterial + row.CostHandling).toFixed(0)}</p>
                            </p>
                            <button type="button" className={this.buttonState(row.State,-1, "danger")} onClick={()=>{this.deleteNest(row.Id)}}><i className='mr-2 fe fe-trash-2'></i> Delete</button>
                            <button type="button" className={this.buttonState(row.State,2, "info")} onClick={()=>{this.updateState(row.Id, 2)}}><i className='mr-2 fe fe-dollar-sign'></i> Quote</button>
                            <button type="button" className={this.buttonState(row.State,1, "success")} onClick={()=>{this.updateState(row.Id, 1)}}><i className='mr-2 fe fe-check-circle'></i> Complete / Cut</button>
                            </div>
                          </div>)
                        })}
                    </div>

                )
            }
    }

    render(){
        const {searchComponents} = this.state;

        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-sunset"></i> Laser Nests</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline w-100 d-block">
                            <input className="form-control mr-sm-2 ml-3" type="search" name="Description" value={searchComponents.Description} ref={(input) => { this.searchInput = input; }} onChange={this.onSearch} placeholder="Search" aria-label="Search"/> 

                            <select className="form-control ml-2" name="Thickness" value={searchComponents.Thickness} onChange={this.onSearch}>
                                    <option value="-1">All</option>
                                        {Pricing.allThickness().map((t, i)=>{
                                            return (<option key={i} value={t}>{t.toFixed(1)}mm</option>)
                                        })}
                            </select>

                            <select className="form-control ml-2" value={searchComponents.Material} name="Material" onChange={this.onSearch}>
                                    <option value="all">All</option>
                                    {Pricing.Material.map((m)=>{
                                        return (<option value={m.Key}>{m.Name}</option>)
                                    })}
                            </select>

                            
                            <button type="button" className={searchComponents.State==0?"form-control ml-3 btn-warning":"form-control ml-3 btn-outline-warning"} onClick={(e)=>{this.setFilter(0)}}><i className='mr-2 fe fe-shuffle'></i> Not Allocated</button>     
                            <button type="button" className={searchComponents.State==2?"form-control ml-3 btn-info":"form-control ml-3 btn-outline-info"}  onClick={(e)=>{this.setFilter(2)}}><i className='mr-2 fe fe-dollar-sign'></i> Quote</button>    
                            <button type="button" className={searchComponents.State==1?"form-control ml-3 btn-success":"form-control ml-3 btn-outline-success"} onClick={(e)=>{this.setFilter(1)}}><i className='mr-2 fe fe-check-circle'></i> Complete / Cut</button>         

                            <button type="button" className="form-control mr-3 btn-secondary float-right " onClick={this.clearSearch}><i className='mr-2 fe fe-check-cross'></i> Clear</button>          
                        </form>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
                <BottomScrollListener onBottom={this.atBottom} />;
            </main>

            
        )
    }
}

export default Nests;