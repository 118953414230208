import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import Pricing from './Pricing.js';
import TimeAgo from '../../Components/TimeAgo.js';
import {PortraitOrientation} from '../../PortraitOrientation.jsx'

class NestEditPrint extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading supplier...",
        redirect: "",
        processes: [],
        updateRequired: false,
        data: null,
        JobIdSuggestions: [],
        history: [],
        JobIdSearch: "",
        rowClicked: -1,
        modalApplyToAll: false,
        firstJobAssociated: false,
    }
   
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    load = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Nest`);
        if(params.NestId==="-1"){
            t.setState({
                redirect: "/nests",
                loadingMsg: "Loading nest...",
                loading: false
            });
        }
        else{
            var data = await databaseRequest(`/nests/${params.NestId}`,"GET");
            data.Prediction = data.CostCutting == 0;
            var history = await databaseRequest(`/history/${data.Guid}`,"GET");

            setPage(`${data.JobName} - ${data.Thickness.toFixed(1)}mm`);
            t.setState({
                loading: false,
                data: Pricing.processData(data),
                history: history,
                updateRequired: false
            });
        }
    }
    async componentDidMount(){
        const {databaseRequest}=this.props;
        var Jobs = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20`, 'GET');
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        this.setState({
            Companies: companies,
            Contacts: contacts,
            Jobs: Jobs
        });
        this.load();
    }

    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    jobName = (Id) =>{
        var r = this.getJobIdSuggestions(Id);
        if(r.length==1){
            return (<div style={{fontSize: "0.6rem"}} >{this.renderJobIdSuggestion(r[0])}</div>);
        }
        return "Job No."
    }
    getJobIdSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.Jobs.filter(lang =>
          lang.Id.toString().slice(0, inputLength) === inputValue
        );
    };
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                
                return Companies[i].Name;
            }
        }
        return null;
    }
    renderJobIdSuggestion = suggestion => (
        
        <div>
          {suggestion.Id} - {this.getCompanyName(suggestion.CompanyId, suggestion.ContactId)} | {suggestion.Name}
        </div>
    );
    partsList1 = ()=>{
        const {Parts} = this.state.data;

        function compare( a, b ) {
            if ( a.JobId < b.JobId ){
              return -1;
            }
            if ( a.JobId > b.JobId ){
              return 1;
            }
            return 0;
          }
          
          Parts.sort( compare );


        var checkJobId = -1;

        var lastJobId = -1;
        var lastCompany = "";
        var lastJobName = "";


        var lastPrice = 0;
        var lastQty = 0;

        var totalPrice = 0;
        var totalQty = 0;
        var displayTotal = false;
        return (
            <table className="m-2 table w-100  table-bordered">
            <thead className="thead-dark">
            <tr>
                <th>#</th>
                <th>Job No.</th>
                <th>Customer</th>
                <th style={{width: '15rem'}}>Price</th>
                <th>File</th>
                <th></th>
            </tr>
            </thead>
        <tbody> {Parts.map((row, i) => {
            var r = this.getJobIdSuggestions(row.JobId.toString());
            var Company = " - "
            var JobName = " - "
            if(r.length==1){
                var suggestion = r[0];
                JobName = suggestion.Name;
                Company = this.getCompanyName(suggestion.CompanyId, suggestion.ContactId)
            }


            
            if(checkJobId == row.JobId || i==0){
                totalPrice += row.Quantity*row.CostEach;
                totalQty += row.Quantity;
                lastJobId = row.JobId;
                lastCompany = Company;
                lastJobName = JobName;
            }
            else{
                lastPrice = row.Quantity*row.CostEach;
                lastQty = row.Quantity;
                displayTotal = true;
            }
            checkJobId = row.JobId;

            function summary(){
                var _field = (<tr>
                    <td colSpan={6} style={{fontSize: "0.8rem"}}>
                        <div className='row'>
                            <div className='col-3 mx-auto text-center font-weight-bold'>{lastJobId} - {lastCompany} <em>{lastJobName}</em></div>
                        </div>   
                        <div className='row'>
                            <div className='col-3 mx-auto text-center font-weight-bold'>{totalQty} <small>part(s)</small></div>
                        </div>   
                        <div className='row'>
    
                            <div className='col-3 mx-auto text-center font-weight-bold'>${totalPrice.toFixed(2)}</div>
                        </div>   
                      </td>
                </tr>);

                lastJobId = row.JobId;
                lastCompany = Company;
                lastJobName = JobName;
                displayTotal=false;
                totalPrice=lastPrice;
                totalQty=lastQty;

                return _field;
            }
            return (
                <>
                {displayTotal && checkJobId!=-1?(
                summary()
                ):(<></>)}
                <tr>
                    <td className='text-wrap p-1' style={{fontSize: "0.8rem"}}><strong>{i+1}</strong></td>
                    <td className='text-wrap p-1' style={{fontSize: "0.8rem"}}>{row.JobId!=-1?(<>{row.JobId.toString()}</>):(<> - </>)}</td>
                    <td className='text-wrap p-3' style={{fontSize: "0.8rem"}}><strong>{Company}</strong><br/><small>{JobName}</small></td>
                    <td style={{fontSize: "0.8rem"}} className='text-wrap p-3'>
                        <div className='row'>
                            <div className='col-4 text-right'><small>Cut: </small></div>
                            <div className='col-7 text-right'>${row.CostCut.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 text-right'><small>Material: </small></div>
                            <div className='col-7 text-right'>${row.CostMat.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 text-right'><small>Handling: </small></div>
                            <div className='col-7 text-right'>${row.CostHand.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 text-right'><small>Qty: </small></div>
                            <div className='col-7 text-right'>{row.Quantity} off</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 text-right'><small>Each: </small></div>
                            <div className='col-7 text-right'>${row.CostEach.toFixed(2)}</div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-4 text-right font-weight-bold'><strong>Total: </strong></div>
                            <div className='col-7 text-right font-weight-bold'>${(row.Quantity*row.CostEach).toFixed(2)}</div>
                        </div>

                    </td>
                    <td className='text-wrap p-1'>
                        {row.Name.split("\\r\\n").map((text,j)=>{
                            if(text.length > 0){return (<p className='m-0'> &gt; <small>{this.camelize(text)}</small></p>);}
                        
                        })}
                    </td>
                    <td><img className="nest-img-print card-img-top card-img-bottom mx-auto mt-2" src={row.Image} style={{height: "130px", width: "130px"}} alt="Card image cap" /></td>
                </tr>
                {i==Parts.length-1 && checkJobId!=-1?(
                summary()
                ):(<></>)}
                </>
            )
        })}
        </tbody>
        </table>
        );
    }
    partsList = ()=>{
        const {data} = this.state;
        const {Parts} = this.state.data;

        function compare( a, b ) {
            if ( a.JobId < b.JobId ){
              return -1;
            }
            if ( a.JobId > b.JobId ){
              return 1;
            }
            return 0;
          }
          
          Parts.sort( compare );

        return (
        <> {Parts.map((row, i) => {



            return (
                <div  key={i} className="card w-20 m-1 border-blk p-1" style={{width: "210px"}}>
                <img className="nest-img-print card-img-top card-img-bottom mx-auto mt-2" src={row.Image} style={{height: "180px", width: "180px"}} alt="Card image cap" />
                <div className="card-body p-1">
                    <h5 className="card-title"><strong>Item {i+1}</strong> <em>({row.BoundingBoxX.toFixed(1)} x {row.BoundingBoxY.toFixed(1)}) {row.Weight.toFixed(3)}kg</em></h5>
                    <div className="card-text">
                        {
                        row.Name.split("\\r\\n").map((text,j)=>{
                            if(text.length > 0){return (<p key={j} className='mb-0 mt-0 p-0' style={{fontSize: '0.75rem'}}> &gt; {this.camelize(text)}</p>);}
                        
                        })}
                         <p className='font-weight-bold mt-2 text-right'>${row.CostEach.toFixed(2)}ea - {row.Quantity} off</p>

                        {data.State==1 && row.JobId!=-1 && false?(<>
                            <p className="w-100 text-center text-black">{this.jobName(row.JobId.toString())}</p>
                        </>):(<></>)}

                    </div>
                </div>
                </div>
            )





        })}
        </>
        );
    }
    stateName = (s)=>{
        if(s==0)return "Not allocated";
        if(s==1)return "Cut Nest";
        if(s==2)return "Quote";
        return "";
    }
    page = () =>{
        const {props} = this;


        const {history} = this.state;
        var lastUpdated = "";
        if(history.length > 0){
            lastUpdated = history[0].Name;
        }

        if(this.state.loading){
            return this.loading();
        }
        return (
            <main role="main" className="p-5 text-black">
                <PortraitOrientation/>
                <div className="row">
                    <div className="col p-0">
                            <h1 className="border-bottom">{this.data("JobName")} | {this.stateName(this.data("State")).toUpperCase()}</h1>
                            <h5><b><TimeAgo time={this.data("CreatedDateTime")} /></b> ({new Date(this.data("CreatedDateTime")).toLocaleString()})</h5>
                            <small className="text-black">Printed: {new Date().toLocaleString()}</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 p-1">
                    <table className="table w-100  table-bordered">
                        <tr>
                            <th>Material</th>
                            <td style={{fontSize: "0.85rem"}}>{Pricing.activeMaterial().Name} - <small>{this.data("MaterialKey")}</small></td>
                        </tr>
                        <tr>
                            <th>Thickness</th>
                            <td>{this.data("Thickness").toFixed(1)} mm</td>
                        </tr>
                        <tr>
                            <th>Run Time</th>
                            <td>{this.minsToTime(this.data("AllowedRunTime"))} - <small>{this.minsToTime(this.data("RunTime"))}</small></td>
                        </tr>
                        <tr>
                            <th>Sheet Size</th>
                            <td>{this.data("SheetSizeX").toFixed(0)} x {this.data("SheetSizeY").toFixed(0)} - <small>{this.data("Weight").toFixed(1)}kg</small></td>
                        </tr>
                        <tr>
                            <th>Sheet Usage</th>
                            <td>{this.data("SheetUsage").toFixed(2)} / {this.data("SheetArea").toFixed(2)} m2 <small>({this.data("SheetRatio").toFixed(1)}%)</small></td>
                        </tr>
                        <tr>
                            <th>Cutting Cost</th>
                            <td>$ {(this.data("CostCuttingString"))} - <small>${this.data("PricePerMin").toFixed(2)} /min</small></td>
                        </tr>
                        <tr>
                            <th>Material Cost</th>
                            <td>$ {(this.data("CostMaterialString"))} - <small>${this.data("PricePerKg").toFixed(2)} /kg</small></td>
                        </tr>
                        <tr>
                            <th>Handling Cost</th>
                            <td>$ {(this.data("CostHandlingString"))}</td>
                        </tr>
                        <tr>
                            <th>Total Cost</th>
                            <td>$ {(this.data("CostTotal")).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Priced By</th>
                            <td>{lastUpdated}</td>
                        </tr>
                    </table>
                    </div>
                    <div className="col-6 p-1">
                    <img src={this.data("Image")} style={{display:"block"}} className="nest-img-print card-img-top card-img-bottom" alt="..." />
                    </div>
                </div>

                <div className="row">

                {this.partsList()}
                </div>
                </main>
                )
    }
    render() {
        return this.page();
    }
    camelize(str) {
        str = str.toLowerCase();
        return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        }).replace(/\s+/g, ' ');
    }
    minsToTime(min){
        var hh = Math.floor(min/60);
        var mm = (min - (hh*60));
        var ss = (min%1)*60;
        var hhStr = hh<10?"0"+hh.toFixed(0):hh.toFixed(0);
        var mmStr = mm<10?"0"+mm.toFixed(0):mm.toFixed(0);
        var ssStr = ss<10?"0"+ss.toFixed(0):ss.toFixed(0);
        return  hhStr+ ":" + mmStr + ":" +  ssStr;
    }
}

export default NestEditPrint;