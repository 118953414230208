import React, { Component } from 'react';
import {Link} from "react-router-dom";
import queryString from "query-string";
import TimeAgo from '../../Components/TimeAgo.js';
class PurchaseOrders extends Component {
    state = {
        loading: true,
        search: "",
        data: [],
        sort:{
            Id: 1,
        },
    }
    onSearch = (search) => {
        const {databaseRequest}=this.props;
        this.setState({loading: true, search: search});
        this.props.history.replace(`${this.props.location.pathname}?q=${search}`)
        databaseRequest(`/purchase-orders?SupplierName:or_like=%25${search}%25&Description:or_like=%25${search}%25&Id:or_like=%25${search}%25`,"GET").then((POs)=>{
            this.setState({
                loading: false,
                POs: POs
            });
            this.sortJobId();
        });
    }
    sortJobId = ()=>{
        var {POs, sort}=this.state;
        if(sort.Id==-1 || sort.Id==0){
            POs = POs.sort( ( a, b ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=1;
        }
        else if(sort.Id==1){
            POs = POs.sort( ( b, a ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=-1;
        }
        this.setState({POs: POs, sort: sort})
    }
    componentDidMount = async () =>{
        const {databaseRequest,setPage}=this.props;
        var query = queryString.parse(this.props.location.search);
        var Suppliers = await databaseRequest("/suppliers","GET");
        var Users = await databaseRequest("/users","GET");

        let t =this;
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;
        }
        t.jobInput.focus();
        setPage(`Suppliers`);
        var POs = await databaseRequest(`/purchase-orders`,"GET"); //?Name:like=%25${search}%25

        t.setState({
            Suppliers: Suppliers,
            Users: Users,
            search: search,
            POs: POs,
            loading: false
        })
        this.sortJobId();
            

        
    } 
    getSupplier = (SupplierId)=>{
        const {Suppliers} = this.state;
        for(var i=0; i<Suppliers.length; i++){
            if(Suppliers[i].Id==SupplierId){
                return Suppliers[i];
            }
        }
    }
    getUser = (UserId)=>{
        const {Users} = this.state;
        
        for(var i=0; i<Users.length; i++){
            if(Users[i].Id==UserId){
                return Users[i];
            }
        }
        return {
            Name: "null"
        }
    }
    table = () => {    
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading purchase orders...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                        <th scope="col"  style={{width: '6rem'}} className="cursor-pointer" onClick={this.sortJobId}>
                        PO No. {this.state.sort.Id==1?(<i className="fe fe-chevron-down mr-2"></i>):this.state.sort.Id==-1?(<i className="fe fe-chevron-up mr-2"></i>):(<i className="fe fe-minus mr-2"></i>)}
                        </th>
                        <th scope="col">Supplier</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Created By</th>
                        <th scope="col" className='text-right' style={{width: '12rem'}}>Created</th>

                        
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.POs.map((row, i) => {
                        var Supplier = this.getSupplier(row.SupplierId);
                        var User = this.getUser(row.UserId);
                        return (
                        <tr key={i}>                   
                            <th scope="row" ><Link to={`/purchasing/${row.Id}`}>{row.Id}</Link></th>
                            <td><Link to={`/purchasing/${row.Id}`}>{Supplier.Name}</Link></td>
                            <td>{Supplier.Number}</td>
                            <td>{User.Name}</td>
                            <td className="text-right"><p><b><TimeAgo time={row.CreatedDateTime} /></b></p> ({new Date(row.CreatedDateTime).toLocaleDateString()})</td>

                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-dollar-sign"></i> Purchase Orders</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input ref={(input) => { this.jobInput = input; }} className="form-control mr-sm-2" type="search" value={this.state.search} onChange={(event)=>{this.onSearch(event.target.value)}} placeholder="Search" aria-label="Search"/> 
                            <div className="form-group col-md-1 align-self-end">
                                <button type="button" className="btn btn-danger" onClick={()=>{this.onSearch("")}}><i className="fe fe-x"></i></button>
                            </div>                     
                        </form>

                    </div>
                    <div className="col p-0">
                        <Link to="/purchasing/-1"><button type="button" className="btn btn-success float-right">New Purchase Order</button></Link>
                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
            </main>

            
        )
    }
}

export default PurchaseOrders;